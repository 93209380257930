import { createFileRoute } from '@tanstack/react-router'
import node, {
  type checksConfigurationPageQuery,
} from '#gen/relay/checksConfigurationPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/',
)({
  staticData: { title: '[graph] - Operation Checks' },
  loader({ context, params }) {
    return context.preload<checksConfigurationPageQuery>(node, {
      ...params,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/checks/checks-configuration-page'),
    'ChecksConfigurationPage',
  ),
})
