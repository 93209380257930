import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, string } from 'valibot'
import node, {
  type schemaDocumentationPageQuery,
} from '#gen/relay/schemaDocumentationPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const SearchParamsSchema = object({
  activePrimaryTab: optional(string()),
  activeTertiaryTab: optional(string()),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/',
)({
  staticData: { title: '[graph] - Schema' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  errorComponent: ErrorHandler,

  loader({ context, params }) {
    return context.preload<schemaDocumentationPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/schema-documentation-page'),
    'SchemaDocumentationPage',
  ),
})
