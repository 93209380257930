/**
 * @generated SignedSource<<badc47aa2f53a9e326d9706d10f0e596>>
 * @relayHash d9a80a0db66f23bfbf8f36c9ee6cbf70
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 52a5c03a5d912f5d7311f6c811d036259af551ef023ceb78fe224083a8dfbc63

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type graphGeneralSettingsPageQuery$variables = {
  account: string;
  graph: string;
};
export type graphGeneralSettingsPageQuery$data = {
  readonly graph: {
    readonly " $fragmentSpreads": FragmentRefs<"graphNameFragment">;
  };
};
export type graphGeneralSettingsPageQuery = {
  response: graphGeneralSettingsPageQuery$data;
  variables: graphGeneralSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphGeneralSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "graph",
          "args": (v1/*: any*/),
          "concreteType": "Graph",
          "kind": "LinkedField",
          "name": "graphByAccountSlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "graphNameFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphGeneralSettingsPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9a80a0db66f23bfbf8f36c9ee6cbf70",
    "id": "52a5c03a5d912f5d7311f6c811d036259af551ef023ceb78fe224083a8dfbc63",
    "metadata": {},
    "name": "graphGeneralSettingsPageQuery",
    "operationKind": "query",
    "text": "query graphGeneralSettingsPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...graphNameFragment\n    id\n  }\n}\n\nfragment graphNameFragment on Graph {\n  id\n  slug\n  account {\n    __typename\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e27723c8474a50063541ba8e2faead0";

export default node;
