import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'
import { lazy } from 'react'
import { readAndStoreUTMAttributes } from '#app/third-party/utm-attrs'
import { NotFoundPage } from '#lib/errors/errors'

type Context = {}

declare module '@tanstack/react-router' {
  interface StaticDataRouteOption {
    title: string
  }
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

export const Route = createRootRouteWithContext<Context>()({
  staticData: { title: 'Dashboard - Grafbase' },
  notFoundComponent: NotFoundPage,
  beforeLoad: ({ location }) => {
    readAndStoreUTMAttributes(location.searchStr)
  },
  component: function RootRoute() {
    return (
      <>
        <Outlet />
        <TanStackRouterDevtools />
      </>
    )
  },
})
