/**
 * @generated SignedSource<<5cb6cd972d6c9fa12e7d21c6ff3d3ba5>>
 * @relayHash 92debb4b9b0b815b160e55a3ce8eddfd
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bcc9fab1abc2b6695b1709f7144833539a66f847ad0f3d3946eeaa6c5fa4823a

import { ConcreteRequest } from 'relay-runtime';
export type schemaDocumentationPageQuery$variables = {
  account: string;
  branch: string;
  graph: string;
};
export type schemaDocumentationPageQuery$data = {
  readonly branch: {
    readonly federatedSchema: string | null | undefined;
    readonly schema: string | null | undefined;
  };
};
export type schemaDocumentationPageQuery = {
  response: schemaDocumentationPageQuery$data;
  variables: schemaDocumentationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branch"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schema",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "federatedSchema",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "schemaDocumentationPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "Branch",
          "kind": "LinkedField",
          "name": "branch",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "schemaDocumentationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92debb4b9b0b815b160e55a3ce8eddfd",
    "id": "bcc9fab1abc2b6695b1709f7144833539a66f847ad0f3d3946eeaa6c5fa4823a",
    "metadata": {},
    "name": "schemaDocumentationPageQuery",
    "operationKind": "query",
    "text": "query schemaDocumentationPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n) {\n  branch(accountSlug: $account, graphSlug: $graph, name: $branch) {\n    schema\n    federatedSchema\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b6370fdfefb39cdf30fdc84ae31af81";

export default node;
