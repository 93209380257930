import clsx from 'clsx'
import type { ComponentProps } from 'react'
import { type UiIconName, UiIconSvg } from '#gen/sprites/ui-icon'
import { iconStyle } from './icon.css'

const ICON_SIZE = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 56,
}

export interface IconProps {
  icon: UiIconName

  /**
   * xs: 12px,
   * sm: 16px,
   * md: 20px,
   * lg: 24px
   * xl: 32px
   * 2xl: 40px
   */
  size: keyof typeof ICON_SIZE
}

export function Icon({
  icon,
  size,
  ...props
}: IconProps & ComponentProps<'svg'>) {
  const sizeValue = ICON_SIZE[size]
  if (!sizeValue) throw new Error(`Invalid icon size: ${size}`)

  return (
    <UiIconSvg
      {...props}
      assetName={icon}
      width={sizeValue}
      height={sizeValue}
      style={
        {
          ...props.style,
          '--icon-size': `${sizeValue}px`,
        } as React.CSSProperties
      }
      className={clsx(iconStyle, props.className)}
    />
  )
}

export * from '#gen/sprites/ui-icon'
