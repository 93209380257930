/**
 * @generated SignedSource<<8aeff7680e469e497cbe46d35422b504>>
 * @relayHash 5987b6a6c2883a6c3b47af2bccfbcc87
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 955172b01e3592c7aae02a4be9d184aee288f13dd2efba54f2d4ffeb4fc8c989

import { ConcreteRequest } from 'relay-runtime';
export type teamsTeamSettingsPageQuery$variables = {
  account: string;
  team: string;
};
export type teamsTeamSettingsPageQuery$data = {
  readonly accountBySlug: {
    readonly __typename: string;
    readonly id: string;
    readonly slug: string;
  } | null | undefined;
  readonly teamBySlug: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
  } | null | undefined;
};
export type teamsTeamSettingsPageQuery = {
  response: teamsTeamSettingsPageQuery$data;
  variables: teamsTeamSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "team"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationSlug",
        "variableName": "account"
      },
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "team"
      }
    ],
    "concreteType": "Team",
    "kind": "LinkedField",
    "name": "teamBySlug",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "account"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "accountBySlug",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "teamsTeamSettingsPageQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "teamsTeamSettingsPageQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5987b6a6c2883a6c3b47af2bccfbcc87",
    "id": "955172b01e3592c7aae02a4be9d184aee288f13dd2efba54f2d4ffeb4fc8c989",
    "metadata": {},
    "name": "teamsTeamSettingsPageQuery",
    "operationKind": "query",
    "text": "query teamsTeamSettingsPageQuery(\n  $account: String!\n  $team: String!\n) {\n  teamBySlug(organizationSlug: $account, slug: $team) {\n    id\n    name\n    slug\n  }\n  accountBySlug(slug: $account) {\n    id\n    slug\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "8971064fd41676ed98821738b75be28b";

export default node;
