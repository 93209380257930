import {
  Children,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  type Ref,
  cloneElement,
  isValidElement,
} from 'react'
import { combinedRefs } from './combined-refs'
import { mergeReactProps } from './mergeReactProps'

interface SlotProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode
  ref?: Ref<HTMLElement>
}

export function Slot(props: SlotProps) {
  const { children, ...slotProps } = props
  const childrenArray = Children.toArray(children)
  const slottable = childrenArray.find(isSlottable) as
    | ReactElement<PropsWithChildren>
    | undefined

  if (slottable) {
    // The new element to render is the one passed as a child of `Slottable`
    const newElement = slottable.props
      .children as ReactElement<PropsWithChildren>
    const newChildren = childrenArray.map(child => {
      if (child === slottable) {
        // because the new element will be the one rendered, we are only interested
        // in grabbing its children (`newElement.props.children`)
        if (Children.count(newElement) > 1) return Children.only(null)
        return isValidElement(newElement)
          ? (newElement.props.children as ReactNode)
          : null
      } else {
        return child
      }
    })

    return (
      <SlotClone {...slotProps}>
        {isValidElement(newElement)
          ? cloneElement(newElement, undefined, newChildren)
          : null}
      </SlotClone>
    )
  }

  return <SlotClone {...slotProps}>{children}</SlotClone>
}

interface SlotCloneProps {
  children: ReactNode
  ref?: Ref<HTMLElement>
}

function SlotClone(props: SlotCloneProps) {
  const { children, ref: forwardedRef, ...slotProps } = props

  if (isValidElement(children)) {
    const props = children.props
      ? mergeReactProps(slotProps, children.props)
      : slotProps
    return cloneElement(children, {
      ...props,
      ref: combinedRefs([forwardedRef, (children as any).ref]),
    } as any)
  }

  return Children.count(children) > 1 ? Children.only(null) : null
}

const Slottable = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}

function isSlottable(child: ReactNode): child is ReactElement {
  return isValidElement(child) && child.type === Slottable
}
