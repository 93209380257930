import { createFileRoute } from '@tanstack/react-router'
import node, {
  type invitesPageQuery,
} from '#gen/relay/invitesPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute('/_dashboard/invites/$id')({
  staticData: { title: 'Invited to organization' },
  async loader({ context, params }) {
    return context.preload<invitesPageQuery>(node, {
      inviteId: params.id,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/invites-page'),
    'InvitesPage',
  ),
})
