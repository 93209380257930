import * as v from 'valibot'

const FiltersSchema = v.optional(
  v.array(
    v.union([
      v.tuple([v.literal('operation_name'), v.string()]),
      v.tuple([v.literal('client_name'), v.string()]),
      v.tuple([v.literal('client_version'), v.string()]),
    ]),
  ),
)

export const AnalyticsPageSearchSchema = v.object({
  filters: FiltersSchema,
  timeframe: v.optional(
    v.object({
      range: v.string(),
      from: v.optional(v.string()),
      to: v.optional(v.string()),
    }),
  ),
})

export type AnalyticsPageSearchParams = v.InferOutput<
  typeof AnalyticsPageSearchSchema
>

export type AnalyticFilter = NonNullable<
  AnalyticsPageSearchParams['filters']
>[number]

export type FilterValueTypeByKey<
  FilterKey extends AnalyticFilter[0],
  U extends AnalyticFilter = AnalyticFilter,
> = U extends [FilterKey, infer Value] ? Value : never
