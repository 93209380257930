/**
 * @generated SignedSource<<08d97ec530f8c59f186767e8584e26ef>>
 * @relayHash b4b6827cfd689bd627d77cf40cb801ea
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 743644558a5e1a7c159a86369f86fa9fce09e836f14042bbb1b3485dd9fc1ad5

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountGeneralSettingsPageQuery$variables = {
  account: string;
};
export type accountGeneralSettingsPageQuery$data = {
  readonly accountBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"organizationNameFragment" | "organizationSlugFragment">;
  };
};
export type accountGeneralSettingsPageQuery = {
  response: accountGeneralSettingsPageQuery$data;
  variables: accountGeneralSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountGeneralSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "organizationSlugFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "organizationNameFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountGeneralSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isAccount"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4b6827cfd689bd627d77cf40cb801ea",
    "id": "743644558a5e1a7c159a86369f86fa9fce09e836f14042bbb1b3485dd9fc1ad5",
    "metadata": {},
    "name": "accountGeneralSettingsPageQuery",
    "operationKind": "query",
    "text": "query accountGeneralSettingsPageQuery(\n  $account: String!\n) {\n  accountBySlug(slug: $account) {\n    __typename\n    ...organizationSlugFragment\n    ...organizationNameFragment\n    id\n  }\n}\n\nfragment organizationNameFragment on Account {\n  __isAccount: __typename\n  id\n  name\n}\n\nfragment organizationSlugFragment on Account {\n  __isAccount: __typename\n  id\n  slug\n}\n"
  }
};
})();

(node as any).hash = "1b183b86706f9a246f9adc39c6eb84ae";

export default node;
