/**
 * @generated SignedSource<<e19fdb0d24a7f0889523b88643412b45>>
 * @relayHash 7fd31b0dc49bb0c249cb77a55fec41ae
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c260d72bb74b6c451ea6e57dc402b0f1d8c210bf0b6e1bc7348df405fc3fad07

import { ConcreteRequest } from 'relay-runtime';
export type MemberRole = "ADMIN" | "MEMBER" | "OWNER";
export type personalDangerZonePageQuery$variables = Record<PropertyKey, never>;
export type personalDangerZonePageQuery$data = {
  readonly viewer: {
    readonly organizationMemberships: ReadonlyArray<{
      readonly role: MemberRole;
    }>;
  } | null | undefined;
};
export type personalDangerZonePageQuery = {
  response: personalDangerZonePageQuery$data;
  variables: personalDangerZonePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalDangerZonePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalDangerZonePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fd31b0dc49bb0c249cb77a55fec41ae",
    "id": "c260d72bb74b6c451ea6e57dc402b0f1d8c210bf0b6e1bc7348df405fc3fad07",
    "metadata": {},
    "name": "personalDangerZonePageQuery",
    "operationKind": "query",
    "text": "query personalDangerZonePageQuery {\n  viewer {\n    organizationMemberships {\n      role\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3e18878133b2c82e370fe5a3780e80a";

export default node;
