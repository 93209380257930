import { createFileRoute } from '@tanstack/react-router'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute('/_dashboard/_personal')({
  staticData: { title: '' },
  component: lazyLoadedComponent(
    () => import('#app/pages/personal/personal-account-layout'),
    'PersonalAccountLayout',
  ),
})
