import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import node, {
  type deploymentDetailsDiffPageQuery,
} from '#gen/relay/deploymentDetailsDiffPageQuery.graphql'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id/_deployment/diff',
)({
  staticData: { title: '[graph] - Deployment Schema Diff' },
  loader({ context, params }) {
    return context.preload<deploymentDetailsDiffPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/branch/deployments/deployment-details-diff-page'),
    'DeploymentDetailsDiffPage',
  ),
})
