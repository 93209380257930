import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import { supportClient } from '#app/pages/account/support/support-client'

export const Route = createFileRoute(
  '/_dashboard/$account/support/thread/$threadId',
)({
  staticData: { title: 'Support' },
  async loader({ params }) {
    const response = await supportClient[':account'].threads[':threadId'].$get({
      param: {
        account: params.account,
        threadId: params.threadId,
      },
    })

    if (!response.ok) throw new Error('Failed to get support thread timeline')

    return await response.json()
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/support/support-thread-page'),
    'SupportThreadPage',
  ),
})
