import { createFileRoute } from '@tanstack/react-router'
import node, {
  type accountDangerZonePageQuery,
} from '#gen/relay/accountDangerZonePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/settings/danger-zone',
)({
  staticData: { title: 'Danger zone' },
  async loader({ context, params }) {
    return context.preload<accountDangerZonePageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/account-danger-zone-page'),
    'AccountDangerZonePage',
  ),
})
