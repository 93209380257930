/**
 * @generated SignedSource<<76227bea01394138c72a0613f9444f97>>
 * @relayHash 818b7615f924c5c8e92ef816d6cfcdf9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 575a0e4dea2ddac77c2bbe5825e3c62b16f0c9bd2014af9fe1235dd9d9d5959f

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberRole = "ADMIN" | "MEMBER" | "OWNER";
export type membersPageQuery$variables = {
  account: string;
};
export type membersPageQuery$data = {
  readonly accountBySlug: {
    readonly __typename: "Organization";
    readonly members: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly role: MemberRole;
          readonly user: {
            readonly id: string;
          };
        };
      }>;
    };
    readonly samlDomain: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"invitesSectionOrgMembersFragment" | "membersPageListFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly viewer: {
    readonly id: string;
  };
};
export type membersPageQuery = {
  response: membersPageQuery$data;
  variables: membersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "samlDomain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "membersPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "membersPageListFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "invitesSectionOrgMembersFragment"
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberConnection",
                  "kind": "LinkedField",
                  "name": "members",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MemberEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Member",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "User",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Organization",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "membersPageQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Member",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatarUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InviteConnection",
                "kind": "LinkedField",
                "name": "invites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InviteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Invite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "818b7615f924c5c8e92ef816d6cfcdf9",
    "id": "575a0e4dea2ddac77c2bbe5825e3c62b16f0c9bd2014af9fe1235dd9d9d5959f",
    "metadata": {},
    "name": "membersPageQuery",
    "operationKind": "query",
    "text": "query membersPageQuery(\n  $account: String!\n) {\n  viewer {\n    id\n  }\n  accountBySlug(slug: $account) {\n    __typename\n    ... on Organization {\n      ...membersPageListFragment\n      ...invitesSectionOrgMembersFragment\n      samlDomain\n      members {\n        edges {\n          node {\n            role\n            user {\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment inviteMembersModalFragment on Organization {\n  id\n  slug\n  invites {\n    edges {\n      node {\n        id\n        email\n      }\n    }\n  }\n  members {\n    edges {\n      node {\n        user {\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment invitesSectionOrgMembersFragment on Organization {\n  id\n  slug\n  invites {\n    edges {\n      node {\n        id\n        email\n        createdAt\n      }\n    }\n  }\n}\n\nfragment membersPageListFragment on Organization {\n  slug\n  members {\n    edges {\n      node {\n        id\n        role\n        user {\n          id\n          name\n          email\n          avatarUrl\n        }\n      }\n    }\n  }\n  ...inviteMembersModalFragment\n}\n"
  }
};
})();

(node as any).hash = "a3201f60a9fc641e48f9a8c532b3b694";

export default node;
