/**
 * @generated SignedSource<<ed420d5e941cf7ea3aab280e2950ff72>>
 * @relayHash bc548d282c45156fc3e3ba9e3ca1f1be
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f1db5066650471f724f01a797ca972f1c6cd4becdc6dfd75c9a1ccc10a0fa23c

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type teamsTeamTeamsPageQuery$variables = {
  account: string;
  team: string;
};
export type teamsTeamTeamsPageQuery$data = {
  readonly accountBySlug: {
    readonly __typename: string;
    readonly id: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"teamsTreeFragment">;
  };
  readonly teamBySlug: {
    readonly id: string;
  };
};
export type teamsTeamTeamsPageQuery = {
  response: teamsTeamTeamsPageQuery$data;
  variables: teamsTeamTeamsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "team"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "organizationSlug",
      "variableName": "account"
    },
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "team"
    }
  ],
  "concreteType": "Team",
  "kind": "LinkedField",
  "name": "teamBySlug",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "teamsTeamTeamsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v1/*: any*/),
            (v6/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "teamsTreeFragment"
                }
              ],
              "type": "Organization",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "teamsTeamTeamsPageQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentTeamId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserConnection",
                            "kind": "LinkedField",
                            "name": "members",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TeamConnection",
                            "kind": "LinkedField",
                            "name": "subteams",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TeamEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Team",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v2/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc548d282c45156fc3e3ba9e3ca1f1be",
    "id": "f1db5066650471f724f01a797ca972f1c6cd4becdc6dfd75c9a1ccc10a0fa23c",
    "metadata": {},
    "name": "teamsTeamTeamsPageQuery",
    "operationKind": "query",
    "text": "query teamsTeamTeamsPageQuery(\n  $account: String!\n  $team: String!\n) {\n  teamBySlug(organizationSlug: $account, slug: $team) {\n    id\n  }\n  accountBySlug(slug: $account) {\n    __typename\n    id\n    slug\n    ... on Organization {\n      ...teamsTreeFragment\n    }\n  }\n}\n\nfragment createTeamModalFragment on Organization {\n  teams {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment helpersGetTeamsTreeFragment on Organization {\n  teams {\n    edges {\n      node {\n        id\n        slug\n        name\n        subteams {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment teamsTreeFragment on Organization {\n  id\n  teams {\n    edges {\n      node {\n        id\n        name\n        slug\n        parentTeamId\n        members {\n          totalCount\n        }\n      }\n    }\n  }\n  ...helpersGetTeamsTreeFragment\n  ...createTeamModalFragment\n}\n"
  }
};
})();

(node as any).hash = "7c8d764be1f68c512b823c234395e92a";

export default node;
