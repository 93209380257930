import { Navigate, createFileRoute, getRouteApi } from '@tanstack/react-router'
import { getAccountRedirect } from '#lib/account/account-redirect'

export const Route = createFileRoute('/_dashboard/')({
  staticData: { title: '' },

  async loader({ context }) {
    const accountToRedirectTo = await getAccountRedirect(
      context.relayEnvironment,
    )
    return {
      accountToRedirectTo,
    }
  },
  component: () => {
    const { accountToRedirectTo } = routeApi.useLoaderData()

    if (accountToRedirectTo)
      return (
        <Navigate
          to={'/$account'}
          params={{
            account: accountToRedirectTo,
          }}
          replace
        />
      )
    else return <Navigate to="/new-organization" replace />
  },
})

const routeApi = getRouteApi('/_dashboard/')
