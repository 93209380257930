/**
 * @generated SignedSource<<23f0cb4f454bca722373443a1524ad3a>>
 * @relayHash 1f6219612addca612c3506782b7c14a3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a95e0a648fd9d93c1903e1250a4b99bff26cb51fa73adeb309fbb73d5a541a8e

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subgraphsPageQuery$variables = {
  accountSlug: string;
  branchName: string;
  graphSlug: string;
};
export type subgraphsPageQuery$data = {
  readonly accountBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"manageSubgraphOwnershipModalAllTeamsFragment">;
  };
  readonly branch: {
    readonly id: string;
    readonly name: string;
    readonly subgraphs: ReadonlyArray<{
      readonly name: string;
      readonly owners: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly updatedAt: string;
      readonly url: string;
    }>;
  };
};
export type subgraphsPageQuery = {
  response: subgraphsPageQuery$data;
  variables: subgraphsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphSlug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "accountSlug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "accountSlug",
      "variableName": "accountSlug"
    },
    {
      "kind": "Variable",
      "name": "graphSlug",
      "variableName": "graphSlug"
    },
    {
      "kind": "Variable",
      "name": "name",
      "variableName": "branchName"
    }
  ],
  "concreteType": "Branch",
  "kind": "LinkedField",
  "name": "branch",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Subgraph",
      "kind": "LinkedField",
      "name": "subgraphs",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "owners",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "subgraphsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "manageSubgraphOwnershipModalAllTeamsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": (v7/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "subgraphsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1f6219612addca612c3506782b7c14a3",
    "id": "a95e0a648fd9d93c1903e1250a4b99bff26cb51fa73adeb309fbb73d5a541a8e",
    "metadata": {},
    "name": "subgraphsPageQuery",
    "operationKind": "query",
    "text": "query subgraphsPageQuery(\n  $accountSlug: String!\n  $graphSlug: String!\n  $branchName: String!\n) {\n  accountBySlug(slug: $accountSlug) {\n    __typename\n    ...manageSubgraphOwnershipModalAllTeamsFragment\n    id\n  }\n  branch(accountSlug: $accountSlug, graphSlug: $graphSlug, name: $branchName) {\n    id\n    name\n    subgraphs {\n      name\n      url\n      updatedAt\n      owners {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment manageSubgraphOwnershipModalAllTeamsFragment on Organization {\n  teams {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20747b16806f10e0a132fe9ea1a5456b";

export default node;
