import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, string } from 'valibot'
import node, {
  type subgraphSchemaPageQuery,
} from '#gen/relay/subgraphSchemaPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const SearchParamsSchema = object({
  goToType: optional(
    object({
      kind: string(),
      name: string(),
    }),
  ),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph',
)({
  staticData: { title: '[graph] - SDL - Subgraph' },
  errorComponent: ErrorHandler,
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  loader({ context, params }) {
    return context.preload<subgraphSchemaPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/subgraph-schema-page'),
    'SubgraphSchemaPage',
  ),
})
