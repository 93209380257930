import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import node, {
  type teamsTeamMembersPageQuery,
} from '#gen/relay/teamsTeamMembersPageQuery.graphql'

export const Route = createFileRoute(
  '/_dashboard/$account/settings/teams/$team/_team/members',
)({
  staticData: { title: 'Team Members' },
  loader({ context, params }) {
    return context.preload<teamsTeamMembersPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-team-members-page'),
    'TeamsTeamMembersPage',
  ),
})
