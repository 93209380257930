import { createFileRoute } from '@tanstack/react-router'
import node, {
  type checkDetailsPageQuery,
} from '#gen/relay/checkDetailsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/checks/$id',
)({
  staticData: { title: '[graph] - Check' },
  loader({ context, params }) {
    return context.preload<checkDetailsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/checks/check-details-page'),
    'CheckDetailsPage',
  ),
})
