import * as v from 'valibot'

// This flag helps the bundler tree shake code that is only used in our managed
// environment when building for Enterprise Platform.
const IS_MANAGED_BUILD =
  import.meta.IS_MANAGED_BUILD ??
  import.meta.env.VITE_ENTERPRISE_PLATFORM !== 'true'

const IS_ENTERPRISE_BUILD = !IS_MANAGED_BUILD

const GIT_COMMIT_SHA = (
  import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA ||
  import.meta.env.VITE_GIT_COMMIT_SHA
)?.slice(0, 7)

const DEFAULT_CLIENT_VERSION = import.meta.env.DEV ? 'local' : GIT_COMMIT_SHA

// Strategy for exposing config from the server to the client:
// 1. On the server, the config is loaded from (mostly) environment variables on
//    bootstrap.
// 2. The server validates the config against the schema, and errors if it's invalid.
// 3. The server stringifies the config and attaches it to the HTML document.
// 4. On the client, the config is loaded from the HTML document

const EnvironmentSchema = v.union([
  v.literal('production'),
  v.literal('preview'),
  v.literal('development'),
  v.literal('self_hosted'),
])

type Environment = v.InferOutput<typeof EnvironmentSchema>

// This part of the config that must always be available, or the app won't work.
const BaseConfigSchema = v.object({
  ENVIRONMENT: EnvironmentSchema,

  GRAFBASE_API_HOST: v.pipe(v.string(), v.url()),
  WEBSITE_URL: v.optional(v.pipe(v.string(), v.url()), 'https://grafbase.com'),

  CLIENT_NAME: v.optional(v.string(), 'Grafbase Dashboard'),
  CLIENT_VERSION: DEFAULT_CLIENT_VERSION
    ? v.optional(v.string(), DEFAULT_CLIENT_VERSION)
    : v.string(),

  /**  The in-cluster address, for server to server communication */
  ZITADEL_HOST_INTERNAL: v.optional(v.pipe(v.string(), v.url())),

  /** The publicly available address, for browser access */
  ZITADEL_HOST_PUBLIC: v.optional(v.pipe(v.string(), v.url())),
})

// This part of the config is only provided for our managed environment.
const ManagedConfigSchema = v.object({
  ENABLE_TESTING_AUTH: v.optional(v.boolean(), false),

  STRIPE_PUBLISHABLE_KEY: v.optional(v.string()),

  SENTRY: v.optional(
    v.object({
      DSN: v.optional(v.string()),
      ENABLE_TUNNEL: v.optional(v.boolean(), false),
    }),
  ),

  ENABLE_LINKEDIN_ANALYTICS: v.optional(v.boolean(), false),
  ENABLE_VERCEL_ANALYTICS: v.optional(v.boolean(), false),
  ENABLE_VERCEL_SPEED_INSIGHTS: v.optional(v.boolean(), false),

  GOOGLE_TAG_MANAGER_CONTAINER_ID: v.optional(v.string()),

  RUDDERSTACK_WRITE_KEY: v.optional(v.string()),
  RUDDERSTACK_DATAPLANE_URL: v.optional(v.pipe(v.string(), v.url())),

  HYPERTUNE_TOKEN: v.optional(v.string()),
  EDGE_CONFIG: v.optional(v.string()),
  EDGE_CONFIG_HYPERTUNE_ITEM_KEY: v.optional(v.string()),
})

const ConfigSchema = v.object({
  ...BaseConfigSchema.entries,
  ...ManagedConfigSchema.entries,
})

function getConfigFromEnvVars() {
  let ENVIRONMENT: Environment

  if (import.meta.env.DEV) {
    ENVIRONMENT = 'development'
  } else if (IS_ENTERPRISE_BUILD) {
    ENVIRONMENT = 'self_hosted'
  } else if (process.env.VERCEL_ENV)
    ENVIRONMENT = process.env.VERCEL_ENV as Environment
  else {
    throw new Error('Invalid environment value')
  }

  const baseConfig = {
    ENVIRONMENT,
    WEBSITE_URL: process.env.WEBSITE_URL,
    GRAFBASE_API_HOST: process.env.GRAFBASE_API_HOST,

    CLIENT_NAME: process.env.CLIENT_NAME,
    CLIENT_VERSION: process.env.CLIENT_VERSION,
  }

  if (IS_ENTERPRISE_BUILD)
    return {
      ...baseConfig,
      ZITADEL_HOST_INTERNAL: process.env.ZITADEL_HOST_INTERNAL,
      ZITADEL_HOST_PUBLIC: process.env.ZITADEL_HOST_PUBLIC,
    }

  const managedConfig = {
    ENABLE_TESTING_AUTH: process.env.ENABLE_TESTING_AUTH === 'true',

    STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,

    ENABLE_LINKEDIN_ANALYTICS: process.env.ENABLE_LINKEDIN_ANALYTICS === 'true',
    ENABLE_VERCEL_ANALYTICS:
      process.env.ENABLE_VERCEL_ANALYTICS === 'true' &&
      Boolean(process.env.VERCEL_URL),
    ENABLE_VERCEL_SPEED_INSIGHTS:
      process.env.ENABLE_VERCEL_SPEED_INSIGHTS === 'true' &&
      Boolean(process.env.VERCEL_URL),

    GOOGLE_TAG_MANAGER_CONTAINER_ID:
      process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,

    RUDDERSTACK_WRITE_KEY: process.env.RUDDERSTACK_WRITE_KEY,
    RUDDERSTACK_DATAPLANE_URL: process.env.RUDDERSTACK_DATAPLANE_URL,

    HYPERTUNE_TOKEN: process.env.HYPERTUNE_TOKEN,
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    EDGE_CONFIG_HYPERTUNE_ITEM_KEY: process.env.EDGE_CONFIG_HYPERTUNE_ITEM_KEY,
  } as v.InferInput<typeof ManagedConfigSchema>

  if (process.env.SENTRY_DSN) {
    managedConfig.SENTRY = {
      DSN: process.env.SENTRY_DSN,
      ENABLE_TUNNEL: Boolean(process.env.VERCEL_ENV),
    }
  }

  return { ...baseConfig, ...managedConfig }
}

let config: v.InferOutput<typeof ConfigSchema>

export const APP_CONFIG_WINDOW_VAR_NAME = '__APP_CONFIG__'

if (typeof document !== 'undefined') {
  // On the client we simply grab the config from window. We know it has been
  // validated on the server.
  config = window[APP_CONFIG_WINDOW_VAR_NAME]

  if (!config) throw new Error('App config was not provided in the document')
} else {
  // On the server we load the config from env vars
  const result = v.safeParse(ConfigSchema, getConfigFromEnvVars())
  if (result.success) config = result.output
  else {
    console.error(JSON.stringify(result.issues, null, 2))
    throw new Error('Invalid config')
  }
}

export const Config = {
  ...config,

  // Attach IS_MANAGED_BUILD directly to the config without validating through
  // the schema to make sure dead code elimination works as intended.
  IS_MANAGED_BUILD,
  IS_ENTERPRISE_BUILD,
}
