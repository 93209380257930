/**
 * @generated SignedSource<<e1f4dda5a82d6dca3eb3280326258aee>>
 * @relayHash e41f769c936ad183b31a932c142366da
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 55fe23fa602bb176ca2433c0cba5157b7af8d4e3965e1f3ee9bacb4fa1b29dd0

import { ConcreteRequest } from 'relay-runtime';
export type pathfinderPageQuery$variables = {
  account: string;
  branch: string;
  graph: string;
};
export type pathfinderPageQuery$data = {
  readonly branch: {
    readonly endpointConfig: {
      readonly url: string;
    } | null | undefined;
    readonly federatedSchema: string | null | undefined;
    readonly schema: string | null | undefined;
  } | null | undefined;
};
export type pathfinderPageQuery = {
  response: pathfinderPageQuery$data;
  variables: pathfinderPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branch"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schema",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "federatedSchema",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "EndpointConfig",
  "kind": "LinkedField",
  "name": "endpointConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pathfinderPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "pathfinderPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e41f769c936ad183b31a932c142366da",
    "id": "55fe23fa602bb176ca2433c0cba5157b7af8d4e3965e1f3ee9bacb4fa1b29dd0",
    "metadata": {},
    "name": "pathfinderPageQuery",
    "operationKind": "query",
    "text": "query pathfinderPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n) {\n  branch(accountSlug: $account, graphSlug: $graph, name: $branch) {\n    schema\n    federatedSchema\n    endpointConfig {\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "308ab30abcaf56716c51ead379e76236";

export default node;
