import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import node, {
  type deploymentDetailsBuildInputsPageQuery,
} from '#gen/relay/deploymentDetailsBuildInputsPageQuery.graphql'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id/_deployment/build-inputs',
)({
  staticData: { title: '[graph] - Deployment Build Inputs' },
  loader({ context, params }) {
    return context.preload<deploymentDetailsBuildInputsPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () =>
      import(
        '#app/pages/branch/deployments/deployment-details-build-inputs-page'
      ),
    'DeploymentDetailsBuildInputsPage',
  ),
})
