import { createFileRoute } from '@tanstack/react-router'
import node, {
  type graphDangerZonePageQuery,
} from '#gen/relay/graphDangerZonePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone',
)({
  staticData: { title: '[graph] - Danger Zone' },
  async loader({ context, params }) {
    return context.preload<graphDangerZonePageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-danger-zone-page'),
    'GraphDangerZonePage',
  ),
})
