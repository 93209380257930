import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import node, {
  type teamsTeamLayoutQuery,
} from '#gen/relay/teamsTeamLayoutQuery.graphql'

export const Route = createFileRoute(
  '/_dashboard/$account/settings/teams/$team/_team',
)({
  staticData: { title: 'Team' },
  staleTime: 30_000,
  loader({ context, params }) {
    return context.preload<teamsTeamLayoutQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-team-layout'),
    'TeamsTeamLayout',
  ),
})
