import { createFileRoute } from '@tanstack/react-router'
import node, {
  type subgraphsPageQuery,
} from '#gen/relay/subgraphsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/subgraphs',
)({
  staticData: { title: '[graph] - Subgraphs' },
  loader({ context, params }) {
    return context.preload<subgraphsPageQuery>(node, {
      accountSlug: params.account,
      graphSlug: params.graph,
      branchName: params.branch,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/subgraphs/subgraphs-page'),
    'SubgraphsPage',
  ),
})
