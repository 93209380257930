import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import Cookies from 'universal-cookie'
import './global-theme.css'
import './global.css'

export type ResolvedTheme = 'light' | 'dark'
export type Theme = ResolvedTheme | 'system'

const GRAFBASE_THEME_COOKIE_NAME = 'grafbase_theme'

const ThemeContext = createContext<
  [{ theme: Theme; resolvedTheme: 'light' | 'dark' }, (value: Theme) => void]
>([{ theme: 'system', resolvedTheme: 'dark' }, () => {}])

const cookies = new Cookies()
const storedTheme = cookies.get<ResolvedTheme>(GRAFBASE_THEME_COOKIE_NAME)
const initialTheme: Theme = storedTheme ?? 'system'

if (typeof document !== 'undefined') {
  document.body.setAttribute('data-theme', initialTheme)
}

export function ThemeProvider(props: PropsWithChildren) {
  const [theme, _setTheme] = useState(initialTheme)
  const [prefersDarkScheme, setPrefersDarkScheme] = useState(
    typeof window !== 'undefined' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  )

  useEffect(() => {
    const media = window.matchMedia('(prefers-color-scheme: dark)')

    function listener(event: MediaQueryListEvent) {
      setPrefersDarkScheme(event.matches)
    }

    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [])

  function setTheme(newTheme: Theme) {
    if (['light', 'dark'].includes(newTheme)) {
      cookies.set(GRAFBASE_THEME_COOKIE_NAME, newTheme)
      document.body.setAttribute('data-theme', newTheme)
      document
        .getElementsByName('color-scheme')[0]
        ?.setAttribute('content', newTheme)
    } else {
      cookies.remove(GRAFBASE_THEME_COOKIE_NAME)
      document.body.removeAttribute('data-theme')
      document
        .getElementsByName('color-scheme')[0]
        ?.setAttribute('content', 'light dark')
    }

    _setTheme(newTheme)
  }

  const resolvedTheme =
    theme === 'system' ? (prefersDarkScheme ? 'dark' : 'light') : theme

  return (
    <ThemeContext.Provider value={[{ theme, resolvedTheme }, setTheme]}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}
