import { graphql } from 'react-relay'
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'
import type { getEmailCliAuthQuery } from '#gen/relay/getEmailCliAuthQuery.graphql'
import { makeAdhocQuery } from '#lib/relay/use-adhoc-query'

export async function cliAuthPage_getEmail(
  relayEnvironment: RelayModernEnvironment,
) {
  const { viewer } = await makeAdhocQuery<getEmailCliAuthQuery>(
    relayEnvironment,
    graphql`
      query getEmailCliAuthQuery {
        viewer {
          email
        }
      }
    `,
    {},
  )

  return viewer?.email
}
