import { createFileRoute } from '@tanstack/react-router'
import node, {
  type personalDangerZonePageQuery,
} from '#gen/relay/personalDangerZonePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/_personal/settings/danger-zone',
)({
  staticData: { title: 'Danger Zone' },
  async loader({ context }) {
    return context.preload<personalDangerZonePageQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/personal-danger-zone-page'),
    'PersonalDangerZonePage',
  ),
})
