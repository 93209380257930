import { Link } from '@tanstack/react-router'
import { Button } from 'grafbase-components/button'
import { Icon } from 'grafbase-components/icon'
import { supportEmail } from '#lib/company/constants'
import { CustomError } from './custom-error'
import { errorStyles as styles } from './errors.css'

export function NotFoundPage() {
  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.notFoundTitle}>404 | Page not found</h1>

      <Link to="/" className={styles.notFoundLink}>
        Go to dashboard <Icon icon="arrow-right" size="sm" />
      </Link>
    </div>
  )
}

export function ErrorHandler({ error }: { error: unknown }) {
  let errorMessage = ''
  if (error instanceof Error) errorMessage = error.message
  else if (typeof error === 'string') errorMessage = error

  // When we detect a module import error, we reload the page. This is usually
  // caused by version drift, where the version of the app in the user's browser
  // is trying to request the version of the module that was present when the
  // user first loaded the app, while the module has been updated with a new
  // hash by a deployment that happened in between the users first load and
  // navigation to a lazy route.
  // TODO: When we switch from the file based lazy loading to using Tanstack
  // Router's lazyRouteComponent, we can wrap the lazy loading and implement
  // this directly in import try/catch. See
  // https://gist.github.com/cpojer/c50a742ec943d95c3c72a41ac1c06f03 as an example.
  if (
    typeof location !== 'undefined' &&
    (errorMessage.includes('dynamically imported module') ||
      errorMessage.includes('Importing a module script failed'))
  ) {
    location.reload()
    return null
  }

  const errorCouldBeFixedByReloading = !(error instanceof CustomError)

  return (
    <div className={styles.errorContainer}>
      <h3 className={styles.errorTitle}>⚠️ Something went wrong!</h3>

      {errorCouldBeFixedByReloading && (
        <div className={styles.errorMessageContainer}>
          <p>
            Try reloading the page, or{' '}
            <a
              href={`mailto:${supportEmail}`}
              className={styles.errorContactLink}
            >
              contact support
            </a>{' '}
            if the issue persists.
          </p>

          <Button variant="secondary" onClick={() => location.reload()}>
            <span className={styles.errorButtonWithIcon}>
              <Icon icon="refresh" size="sm" />
              Reload page
            </span>
          </Button>
        </div>
      )}

      {errorMessage && (
        <pre className={styles.errorMessage}>{errorMessage}</pre>
      )}

      {!errorCouldBeFixedByReloading && (
        <div className={styles.errorMessageContainer}>
          <Button
            variant="secondary"
            onClick={() => {
              location.pathname = '/'
            }}
          >
            Go back to Home page
          </Button>
        </div>
      )}
    </div>
  )
}
