import { createFileRoute } from '@tanstack/react-router'
import node, {
  type branchLayoutQuery,
} from '#gen/relay/branchLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch',
)({
  staticData: { title: '' },
  staleTime: 30_000,

  // Branch name can contain slashes, @-characters and other things that
  // can mess up our URL parsing, so we need to encode/decode it.
  params: {
    stringify(params) {
      return { ...params, branch: encodeURIComponent(params.branch) }
    },
    parse(params) {
      return { ...params, branch: decodeURIComponent(params.branch) }
    },
  },
  async loader({ context, params }) {
    return context.preload<branchLayoutQuery>(node, {
      account: params.account,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/branch-layout'),
    'BranchLayout',
  ),
})
