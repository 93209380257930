import clsx from 'clsx'
import { vars } from 'grafbase-styles'
import type { CSSProperties, ComponentProps } from 'react'
import { spinnerStyle } from './spinner.css'

type Props = {
  size?: CSSProperties['width']
} & ComponentProps<'div'>

export const Spinner = (props: Props) => {
  const { size = 48 } = props

  return (
    <div
      {...props}
      className={clsx(spinnerStyle, props.className)}
      style={
        {
          '--spinner-size': typeof size === 'number' ? `${size}px` : size,
          ...props.style,
        } as CSSProperties
      }
    />
  )
}

export function FullpageSpinner() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flex: 1,
        color: vars.color.neutral11,
        paddingBlock: '4rem',
      }}
    >
      <Spinner />
    </div>
  )
}
