/**
 * @generated SignedSource<<2596936b50a3c7dc629cf3cd089580d6>>
 * @relayHash 5a54481640b4b3d6229190789a82691f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 13591aac101ed7855ad3186f63e83d7917ae37f960152398ee6f964d826de21e

import { ConcreteRequest } from 'relay-runtime';
export type accountDangerZonePageQuery$variables = {
  account: string;
};
export type accountDangerZonePageQuery$data = {
  readonly accountBySlug: {
    readonly __typename: "Organization";
    readonly id: string;
    readonly slug: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type accountDangerZonePageQuery = {
  response: accountDangerZonePageQuery$data;
  variables: accountDangerZonePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountDangerZonePageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "type": "Organization",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountDangerZonePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a54481640b4b3d6229190789a82691f",
    "id": "13591aac101ed7855ad3186f63e83d7917ae37f960152398ee6f964d826de21e",
    "metadata": {},
    "name": "accountDangerZonePageQuery",
    "operationKind": "query",
    "text": "query accountDangerZonePageQuery(\n  $account: String!\n) {\n  accountBySlug(slug: $account) {\n    __typename\n    ... on Organization {\n      __typename\n      id\n      slug\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "99111ae4c244cedf03b3bd9c2ca3817e";

export default node;
