import type {
  CSSProperties,
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  Ref,
} from 'react'
import { PageTitle } from '../page-title/page-title'
import {
  type MainContainerVariants,
  mainContainerStyles,
} from './main-container.css'

type MainContainerProps = PropsWithChildren<
  MainContainerVariants & {
    tertiaryNav?: ReactNode
    pageTitle?: ComponentProps<typeof PageTitle> | string
    style?: CSSProperties
    ref?: Ref<HTMLDivElement>
  }
>

export function MainContainer({
  children,
  layout,
  tertiaryNav,
  pageTitle,
  style,
  ref,
}: MainContainerProps) {
  return (
    <div
      className={mainContainerStyles.container({
        withTertiaryNav: !!tertiaryNav,
      })}
    >
      {tertiaryNav}

      <div className={mainContainerStyles.mainScrollWrap}>
        <main
          ref={ref}
          className={mainContainerStyles.main({ layout })}
          style={style}
        >
          {pageTitle && (
            <div className={mainContainerStyles.titleContainer}>
              {typeof pageTitle === 'string' ? (
                <PageTitle title={pageTitle} />
              ) : (
                <PageTitle {...pageTitle} />
              )}
            </div>
          )}
          {children}
        </main>
      </div>
    </div>
  )
}
