/**
 * @generated SignedSource<<4b8c60cf8e070af0a4d6cb7ba008ae86>>
 * @relayHash 21abb051b869769af11fd8a390942872
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c3cce89af017dfab2a17371af135d3eb481fb716cf3050e5d7a19b16a7e7c430

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountOverviewPageQuery$variables = {
  account: string;
  from: string;
  to: string;
};
export type accountOverviewPageQuery$data = {
  readonly account: {
    readonly graphs: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly slug: string;
          readonly " $fragmentSpreads": FragmentRefs<"graphCardFragment">;
        };
      }>;
    };
    readonly id: string;
    readonly slug: string;
  };
};
export type accountOverviewPageQuery = {
  response: accountOverviewPageQuery$data;
  variables: accountOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "from"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "to"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountOverviewPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "account",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphConnection",
              "kind": "LinkedField",
              "name": "graphs",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Graph",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "graphCardFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountOverviewPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphConnection",
            "kind": "LinkedField",
            "name": "graphs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Graph",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Branch",
                        "kind": "LinkedField",
                        "name": "productionBranch",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Deployment",
                            "kind": "LinkedField",
                            "name": "latestDeployment",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "from",
                                "variableName": "from"
                              },
                              {
                                "kind": "Variable",
                                "name": "to",
                                "variableName": "to"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "filters"
                          }
                        ],
                        "concreteType": "GraphAnalytics",
                        "kind": "LinkedField",
                        "name": "analytics",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RequestMetricsTimeSeriesV2",
                            "kind": "LinkedField",
                            "name": "requestMetrics",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RequestMetricsTimeSeriesDataPointV2",
                                "kind": "LinkedField",
                                "name": "points",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dateTime",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RequestMetricsV2",
                                "kind": "LinkedField",
                                "name": "overall",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21abb051b869769af11fd8a390942872",
    "id": "c3cce89af017dfab2a17371af135d3eb481fb716cf3050e5d7a19b16a7e7c430",
    "metadata": {},
    "name": "accountOverviewPageQuery",
    "operationKind": "query",
    "text": "query accountOverviewPageQuery(\n  $account: String!\n  $from: DateTime!\n  $to: DateTime!\n) {\n  account: accountBySlug(slug: $account) {\n    __typename\n    id\n    slug\n    graphs {\n      edges {\n        node {\n          slug\n          ...graphCardFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment graphCardAnalyticsFragment on Graph {\n  analytics(filters: {from: $from, to: $to}) {\n    requestMetrics {\n      points {\n        dateTime\n        count\n      }\n      overall {\n        count\n      }\n    }\n  }\n}\n\nfragment graphCardFragment on Graph {\n  slug\n  account {\n    __typename\n    slug\n    id\n  }\n  productionBranch {\n    name\n    latestDeployment {\n      id\n      createdAt\n    }\n    id\n  }\n  ...graphCardAnalyticsFragment\n}\n"
  }
};
})();

(node as any).hash = "a2027ebbb63a1b40691d09266e1e4180";

export default node;
