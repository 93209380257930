import { pageTitleStyles } from './page-title.css'

interface Props {
  title: React.ReactNode
  rowEnd?: React.ReactNode
}

export function PageTitle({ title, rowEnd }: Props) {
  return (
    <div className={pageTitleStyles.container}>
      <h1 className={pageTitleStyles.title} id="page-title">
        {title}
      </h1>
      {rowEnd && <div>{rowEnd}</div>}
    </div>
  )
}
