import { Navigate, createFileRoute } from '@tanstack/react-router'
import { Config } from '#app/config'

export const Route = createFileRoute('/auth/testing')({
  staticData: { title: '' },
  loader: async () => {
    if (!Config.ENABLE_TESTING_AUTH) throw new Error('Testing Auth is disabled')

    return authenticateWithTestingAuth()
  },
  component: () => <Navigate to="/" />,
})

async function authenticateWithTestingAuth() {
  const res = await fetch('/_auth/e2e_test')

  if (!res.ok) throw new Error('Failed to authenticate with testing auth')
}
