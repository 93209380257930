/**
 * @generated SignedSource<<49e273182250f8e27f4490b978694095>>
 * @relayHash 6f1d14def661e01da611b695061dd429
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 852ddf225c571f669907395be5464967e0be6adcb5fc65f925ffcaca0883a9d6

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalsSettingsPageQuery$variables = {
  accountSlug: string;
  branchName: string;
  graphSlug: string;
};
export type proposalsSettingsPageQuery$data = {
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"configuredReviewersOptionsFragment">;
  };
  readonly branch: {
    readonly id: string;
    readonly schemaProposalsConfiguration: {
      readonly requireApprovalFromSubgraphOwners: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"configuredReviewersCurrentFragment" | "proposalsSettingsPageEnforceChecksFragment" | "proposalsSettingsPageKeepApprovalsOnEditFragment">;
    };
  };
};
export type proposalsSettingsPageQuery = {
  response: proposalsSettingsPageQuery$data;
  variables: proposalsSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphSlug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "accountSlug"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "accountSlug"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graphSlug"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branchName"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requireApprovalFromSubgraphOwners",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalsSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "account",
          "args": (v3/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "configuredReviewersOptionsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": "Branch",
          "kind": "LinkedField",
          "name": "branch",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "SchemaProposalsConfiguration",
                "kind": "LinkedField",
                "name": "schemaProposalsConfiguration",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "proposalsSettingsPageEnforceChecksFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "configuredReviewersCurrentFragment"
                  },
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "proposalsSettingsPageKeepApprovalsOnEditFragment"
                  }
                ],
                "storageKey": null
              },
              "action": "THROW"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "proposalsSettingsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Member",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaProposalsConfiguration",
            "kind": "LinkedField",
            "name": "schemaProposalsConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enforceChecks",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "configuredReviewers",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      (v9/*: any*/)
                    ],
                    "type": "SchemaProposalsConfiguredReviewerUser",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "team",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "SchemaProposalsConfiguredReviewerTeam",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "keepApprovalsOnEdit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f1d14def661e01da611b695061dd429",
    "id": "852ddf225c571f669907395be5464967e0be6adcb5fc65f925ffcaca0883a9d6",
    "metadata": {},
    "name": "proposalsSettingsPageQuery",
    "operationKind": "query",
    "text": "query proposalsSettingsPageQuery(\n  $accountSlug: String!\n  $graphSlug: String!\n  $branchName: String!\n) {\n  account: accountBySlug(slug: $accountSlug) {\n    __typename\n    ...configuredReviewersOptionsFragment\n    id\n  }\n  branch(accountSlug: $accountSlug, graphSlug: $graphSlug, name: $branchName) {\n    id\n    schemaProposalsConfiguration {\n      ...proposalsSettingsPageEnforceChecksFragment\n      ...configuredReviewersCurrentFragment\n      requireApprovalFromSubgraphOwners\n      ...proposalsSettingsPageKeepApprovalsOnEditFragment\n    }\n  }\n}\n\nfragment configuredReviewersCurrentFragment on SchemaProposalsConfiguration {\n  configuredReviewers {\n    __typename\n    ... on SchemaProposalsConfiguredReviewerUser {\n      id\n      __typename\n      user {\n        id\n        name\n        avatarUrl\n      }\n    }\n    ... on SchemaProposalsConfiguredReviewerTeam {\n      id\n      __typename\n      team {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment configuredReviewersOptionsFragment on Organization {\n  members {\n    nodes {\n      user {\n        id\n        name\n        avatarUrl\n      }\n      id\n    }\n  }\n  teams {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\nfragment proposalsSettingsPageEnforceChecksFragment on SchemaProposalsConfiguration {\n  enforceChecks\n}\n\nfragment proposalsSettingsPageKeepApprovalsOnEditFragment on SchemaProposalsConfiguration {\n  keepApprovalsOnEdit\n}\n"
  }
};
})();

(node as any).hash = "2eaae81a3cf88f5de77ea9ce3a70e493";

export default node;
