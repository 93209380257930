import { Logo } from '#lib/ui/illustrations/logo'
import { HeaderShell } from './hader-shell'
import { headerLogoStyle } from './header.css'

export function HeaderSkeleton() {
  return (
    <HeaderShell>
      <div className={headerLogoStyle}>
        <Logo />
      </div>
    </HeaderShell>
  )
}
