import { createFileRoute } from '@tanstack/react-router'
import node, {
  type proposalsSettingsPageQuery,
} from '#gen/relay/proposalsSettingsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals',
)({
  staticData: { title: '[graph] - Proposals' },
  async loader({ context, params }) {
    return context.preload<proposalsSettingsPageQuery>(node, {
      accountSlug: params.account,
      graphSlug: params.graph,
      branchName: params.branch,
    })
  },
  component: lazyLoadedComponent(
    () => import('../pages/settings/graph/proposals/proposals-settings-page'),
    'ProposalsSettingsPage',
  ),
})
