/**
 * @generated SignedSource<<af4edecb28af3fc7a107014fd574ad47>>
 * @relayHash 857a80ba0f7eed6dcda8ac4490f12d6e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 59be2f6dab05146a6c3447c289aa38e8408ef73cef84c9e7ac65afeb0b04ba78

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboardLayoutQuery$variables = Record<PropertyKey, never>;
export type dashboardLayoutQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"dashboardLayoutIdentifyUserFragment" | "dynamicPageTitle" | "providerFeatureFlagsFragment" | "selectsDataWrapperAccountsFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"accountPermissionsFragment" | "headerFragment">;
};
export type dashboardLayoutQuery = {
  response: dashboardLayoutQuery$data;
  variables: dashboardLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "headerFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountPermissionsFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dashboardLayoutIdentifyUserFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "selectsDataWrapperAccountsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "providerFeatureFlagsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dynamicPageTitle"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialExpiresAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "857a80ba0f7eed6dcda8ac4490f12d6e",
    "id": "59be2f6dab05146a6c3447c289aa38e8408ef73cef84c9e7ac65afeb0b04ba78",
    "metadata": {},
    "name": "dashboardLayoutQuery",
    "operationKind": "query",
    "text": "query dashboardLayoutQuery {\n  ...headerFragment\n  ...accountPermissionsFragment\n  viewer {\n    ...dashboardLayoutIdentifyUserFragment\n    ...selectsDataWrapperAccountsFragment\n    ...providerFeatureFlagsFragment\n    ...dynamicPageTitle\n    id\n  }\n}\n\nfragment accountPermissionsFragment on Query {\n  viewer {\n    organizationMemberships {\n      role\n      account {\n        __typename\n        id\n        slug\n        plan\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment dashboardLayoutIdentifyUserFragment on User {\n  id\n  name\n  email\n  createdAt\n  avatarUrl\n  organizations {\n    nodes {\n      id\n      slug\n      name\n      plan\n    }\n  }\n}\n\nfragment dynamicPageTitle on User {\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment headerFragment on Query {\n  viewer {\n    ...helpModalFragment\n    ...profileMenuFragment\n    ...headerRemainingTrialTimeMessageFragment\n    id\n  }\n}\n\nfragment headerRemainingTrialTimeMessageFragment on User {\n  organizations {\n    nodes {\n      plan\n      slug\n      trialExpiresAt\n      id\n    }\n  }\n}\n\nfragment helpModalFragment on User {\n  organizations {\n    nodes {\n      name\n      slug\n      id\n    }\n  }\n}\n\nfragment profileMenuFragment on User {\n  name\n  email\n  avatarUrl\n}\n\nfragment providerFeatureFlagsFragment on User {\n  id\n  email\n  name\n  organizations {\n    nodes {\n      id\n      slug\n      plan\n    }\n  }\n}\n\nfragment selectsDataWrapperAccountsFragment on User {\n  ...accountSelectFragment\n}\n"
  }
};
})();

(node as any).hash = "07698ed75f2f093c072c7e37031e843f";

export default node;
