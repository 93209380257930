import { createFileRoute } from '@tanstack/react-router'
import { parseJwt } from 'auth'
import type { JWTPayload } from 'jose'
import { useEffect } from 'react'
import { authClient } from '#lib/auth/auth-client'
import { router } from '../router'

export const Route = createFileRoute('/auth-test-route')({
  staticData: { title: 'Auth test route' },
  async beforeLoad() {
    const token = await authClient.getToken()
    const jwt: JWTPayload = token && parseJwt(token)
    if (!jwt) return { isSignedIn: false }

    const isSignedIn = await authClient.isSignedIn()

    return {
      jwtExpiresIn: jwt.exp
        ? `${Math.round(jwt.exp - Date.now() / 1000)} seconds`
        : 'never',
      isSignedIn,
      jwt,
    }
  },
  component: AuthTestRoute,
})

function AuthTestRoute() {
  const context = Route.useRouteContext()

  useEffect(() => {
    if (context.isSignedIn) {
      const interval = setInterval(() => {
        router.invalidate()
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [context.isSignedIn])

  return (
    <div
      style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 20 }}
    >
      <pre>{JSON.stringify(context, null, 2)}</pre>

      {context.isSignedIn ? (
        <a
          style={{
            color: 'skyblue',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          href="/_auth/signout"
        >
          Sign out
        </a>
      ) : (
        <a
          style={{
            color: 'skyblue',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          href="/_auth/authenticate?redirectUrl=%2Fauth-test-route"
        >
          Sign in
        </a>
      )}
    </div>
  )
}
