import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import { Config } from '#app/config'
import { supportClient } from '#app/pages/account/support/support-client'

export const Route = createFileRoute('/_dashboard/$account/support/')({
  staticData: { title: 'Support' },
  async loader({ params }) {
    if (Config.IS_ENTERPRISE_BUILD) return [] as never

    const response = await supportClient[':account'].threads.$get({
      query: {},
      param: { account: params.account },
    })

    if (response.ok) return await response.json()
    throw new Error('Failed to get support threads')
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/support/support-page'),
    'SupportPage',
  ),
})
