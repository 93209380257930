/**
 * @generated SignedSource<<07b55947eed83ec6a6956fde079a1a67>>
 * @relayHash b0f717ebb431fdb6e0661e9560230b5a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab1f403fdab555d7abfc6904f87af58693ea82b63b50b8e8905a470c9fcc73dd

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type changelogVersionPageQuery$variables = {
  version: string;
};
export type changelogVersionPageQuery$data = {
  readonly schemaVersion: {
    readonly " $fragmentSpreads": FragmentRefs<"changelogVersionFragment">;
  } | null | undefined;
};
export type changelogVersionPageQuery = {
  response: changelogVersionPageQuery$data;
  variables: changelogVersionPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "version"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "changelogVersionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "schemaVersion",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "changelogVersionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "changelogVersionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "schemaVersion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaVersionDelta",
            "kind": "LinkedField",
            "name": "delta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "removed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modified",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaVersionChangeConnection",
            "kind": "LinkedField",
            "name": "changes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaVersionChange",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "path",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0f717ebb431fdb6e0661e9560230b5a",
    "id": "ab1f403fdab555d7abfc6904f87af58693ea82b63b50b8e8905a470c9fcc73dd",
    "metadata": {},
    "name": "changelogVersionPageQuery",
    "operationKind": "query",
    "text": "query changelogVersionPageQuery(\n  $version: ID!\n) {\n  schemaVersion(id: $version) {\n    ...changelogVersionFragment\n    id\n  }\n}\n\nfragment changelogVersionFragment on SchemaVersion {\n  id\n  message\n  createdAt\n  delta {\n    added\n    removed\n    modified\n  }\n  changes {\n    nodes {\n      type\n      path\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "56b5817b11e5bd4f748e86841fb6f9ef";

export default node;
