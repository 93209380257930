import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, string } from 'valibot'
import node, {
  type SchemaProposalStatus,
  type proposalsPageQuery,
} from '#gen/relay/proposalsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const PAGE_SIZE = 10

const SearchParamsSchema = object({
  status: optional(string()),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/',
)({
  staticData: { title: '[graph] - Proposals' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  loaderDeps: ({ search }) => ({ search }),
  loader({ context, params, deps: { search } }) {
    return context.preload<proposalsPageQuery>(node, {
      accountSlug: params.account,
      graphSlug: params.graph,
      branchName: params.branch,
      first: PAGE_SIZE,
      after: null,
      filter: {
        status: (search.status as SchemaProposalStatus) ?? null,
      },
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/proposals/proposals-page/proposals-page'),
    'ProposalsPage',
  ),
})
