// @ts-nocheck

export function loadLinkedin() {
  const _linkedin_partner_id = '5845881'
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  window._linkedin_data_partner_ids.push(_linkedin_partner_id)

  if (!window.lintrk) {
    window.lintrk = function (a, b) {
      window.lintrk.q.push([a, b])
    }
    window.lintrk.q = []
  }

  var s = document.getElementsByTagName('script')[0]
  var b = document.createElement('script')
  b.type = 'text/javascript'
  b.async = true
  b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
  s.parentNode.insertBefore(b, s)
}
