/**
 * @generated SignedSource<<4eade775a7923e4fb4c5e9241f4b04db>>
 * @relayHash 413ba6baa19edc114f35ea74cf21a0e3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2bfbc6d26573a717fe34de7422bd61f3e44fd72ee58da476ae5da73ef44448df

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalPageEditorQuery$variables = {
  proposalId: string;
};
export type proposalPageEditorQuery$data = {
  readonly schemaProposal: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"editorContextFragment" | "editorProposalFragment">;
  };
};
export type proposalPageEditorQuery = {
  response: proposalPageEditorQuery$data;
  variables: proposalPageEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalPageEditorQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editorProposalFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editorContextFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalPageEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaProposalSubgraph",
            "kind": "LinkedField",
            "name": "subgraphs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sdl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "413ba6baa19edc114f35ea74cf21a0e3",
    "id": "2bfbc6d26573a717fe34de7422bd61f3e44fd72ee58da476ae5da73ef44448df",
    "metadata": {},
    "name": "proposalPageEditorQuery",
    "operationKind": "query",
    "text": "query proposalPageEditorQuery(\n  $proposalId: ID!\n) {\n  schemaProposal(id: $proposalId) {\n    id\n    ...editorProposalFragment\n    ...editorContextFragment\n  }\n}\n\nfragment editorContextFragment on SchemaProposal {\n  subgraphs {\n    name\n    sdl\n    status\n  }\n}\n\nfragment editorProposalFragment on SchemaProposal {\n  id\n  title\n  description\n}\n"
  }
};
})();

(node as any).hash = "7a6bad84f2e7bb2254a573d48c67b964";

export default node;
