/**
 * @generated SignedSource<<e80358925920243db8e9b165e2e394c1>>
 * @relayHash 8eba23e4a5cf983b78c183d0c0dd3d30
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f58b41a962d34f6bcfd8dc46c83ef9dd704c4caa91e917c4b64eb9e7188627c6

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type checksPageQuery$variables = {
  account: string;
  graph: string;
};
export type checksPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly schemaChecks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"checksRowFragment">;
        };
      }>;
    };
  };
};
export type checksPageQuery = {
  response: checksPageQuery$data;
  variables: checksPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checksPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Graph",
          "kind": "LinkedField",
          "name": "graphByAccountSlug",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SchemaCheckConnection",
              "kind": "LinkedField",
              "name": "schemaChecks",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SchemaCheckEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SchemaCheck",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "checksRowFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checksPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaCheckConnection",
            "kind": "LinkedField",
            "name": "schemaChecks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaCheckEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaCheck",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "errorCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subgraphName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchemaCheckGitCommit",
                        "kind": "LinkedField",
                        "name": "gitCommit",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "branch",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8eba23e4a5cf983b78c183d0c0dd3d30",
    "id": "f58b41a962d34f6bcfd8dc46c83ef9dd704c4caa91e917c4b64eb9e7188627c6",
    "metadata": {},
    "name": "checksPageQuery",
    "operationKind": "query",
    "text": "query checksPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    schemaChecks {\n      edges {\n        node {\n          id\n          ...checksRowFragment\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment checksRowFragment on SchemaCheck {\n  id\n  createdAt\n  errorCount\n  subgraphName\n  gitCommit {\n    branch\n  }\n}\n"
  }
};
})();

(node as any).hash = "e69a451dbf4b01a34b8e457803b88f27";

export default node;
