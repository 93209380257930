import { createFileRoute } from '@tanstack/react-router'
import node, {
  type proposalDiffPageQuery,
} from '#gen/relay/proposalDiffPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff',
)({
  staticData: { title: '[graph] - Proposal Diff' },
  loader({ context, params }) {
    return context.preload<proposalDiffPageQuery>(node, {
      proposalId: params.id,
    })
  },
  component: lazyLoadedComponent(
    () =>
      import(
        '../pages/branch/proposals/proposal-page/diff-page/proposal-diff-page'
      ),
    'ProposalDiffPage',
  ),
})
