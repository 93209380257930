import { createFileRoute } from '@tanstack/react-router'
import { formatISO } from 'date-fns'
import { object, optional, parse, string } from 'valibot'
import node, {
  type accountOverviewPageQuery,
} from '#gen/relay/accountOverviewPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const SearchParamsSchema = object({
  filter: optional(string()),
})

export const Route = createFileRoute('/_dashboard/$account/')({
  staticData: { title: 'Graphs' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<accountOverviewPageQuery>(node, {
      ...params,
      from: formatISO(new Date(Date.now() - 24 * 60 * 60 * 1_000)),
      to: formatISO(new Date()),
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account-overview/account-overview-page'),
    'AccountOverviewPage',
  ),
})
