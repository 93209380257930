/**
 * @generated SignedSource<<219848ec3274809ec375a0150d3ab114>>
 * @relayHash 2ab207a922cb4bd52cdbbbaedfcdd8f0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 64d870763df419d5b8a590fa794e17da8774b4d8f9d6325d102423e746ae009d

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deploymentDetailsOverviewPageQuery$variables = {
  id: string;
};
export type deploymentDetailsOverviewPageQuery$data = {
  readonly deployment: {
    readonly __typename: "Deployment";
    readonly " $fragmentSpreads": FragmentRefs<"deploymentDetailsOverviewPageStepsFragment">;
  };
};
export type deploymentDetailsOverviewPageQuery = {
  response: deploymentDetailsOverviewPageQuery$data;
  variables: deploymentDetailsOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsOverviewPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Deployment",
          "kind": "LinkedField",
          "name": "deployment",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "deploymentDetailsOverviewPageStepsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeploymentStep",
            "kind": "LinkedField",
            "name": "steps",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "type": "DeploymentStepGeneralError",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ab207a922cb4bd52cdbbbaedfcdd8f0",
    "id": "64d870763df419d5b8a590fa794e17da8774b4d8f9d6325d102423e746ae009d",
    "metadata": {},
    "name": "deploymentDetailsOverviewPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsOverviewPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    __typename\n    ...deploymentDetailsOverviewPageStepsFragment\n    id\n  }\n}\n\nfragment deploymentDetailsOverviewPageStepErrorsFragment on DeploymentStep {\n  errors {\n    __typename\n    ... on DeploymentStepGeneralError {\n      __typename\n      message\n    }\n  }\n}\n\nfragment deploymentDetailsOverviewPageStepsFragment on Deployment {\n  steps {\n    startedAt\n    title\n    status\n    ...deploymentDetailsOverviewPageStepErrorsFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "f167097b5ec531b6a696aad88c79abef";

export default node;
