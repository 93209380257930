import clsx from 'clsx'
import type { ComponentProps, Ref } from 'react'
import type { ExtractVariant } from '../../lib/types/theme.types'
import { Spinner } from '../loaders/spinner'
import { Slot } from '../slot/slot'
import { ButtonStyles as styles } from './button.css'

type Props = {
  isLoading?: boolean
  variant: ExtractVariant<typeof styles.button, 'variant'>
  size?: ExtractVariant<typeof styles.button, 'size'>
  fullWidth?: boolean
  ref?: Ref<HTMLButtonElement>
} & AsChildProps<ComponentProps<'button'>>

type AsChildProps<DefaultElementProps> =
  | ({ asChild?: false } & DefaultElementProps)
  | { asChild: true; children: React.ReactNode }

export function Button({
  isLoading,
  variant,
  size,
  fullWidth,
  children,
  ref,
  ...props
}: Props) {
  if (props.asChild) {
    const { asChild, ...restProps } = props
    return (
      <Slot
        ref={ref}
        className={styles.button({ variant, size, fullWidth })}
        {...restProps}
      >
        {children}
      </Slot>
    )
  }

  return (
    <button
      {...props}
      ref={ref}
      className={clsx(
        styles.button({ variant, size, fullWidth }),
        props.className,
      )}
      type={props.type ?? 'button'}
      data-loading={isLoading ? '' : undefined}
      disabled={props.disabled || isLoading}
    >
      <span className={styles.buttonText}>{children}</span>

      <Spinner size="1rem" className={styles.spinner} />
    </button>
  )
}
