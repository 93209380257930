import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'
import node, {
  type deploymentDetailsSchemaPageQuery,
} from '#gen/relay/deploymentDetailsSchemaPageQuery.graphql'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id/_deployment/schema',
)({
  staticData: { title: '[graph] - Deployment Schema' },
  loader({ context, params }) {
    return context.preload<deploymentDetailsSchemaPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () =>
      import('#app/pages/branch/deployments/deployment-details-schema-page'),
    'DeploymentDetailsSchemaPage',
  ),
})
