import { createFileRoute } from '@tanstack/react-router'
import node, { type sdlPageQuery } from '#gen/relay/sdlPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/',
)({
  staticData: { title: '[graph] - SDL' },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<sdlPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/sdl-page'),
    'SdlPage',
  ),
})
