/**
 * @generated SignedSource<<fb1aa90de394e9f0aa7fa60887519d49>>
 * @relayHash 88c173a39fdcbf6f4e677d63504dedce
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7e12191ee8da6812444102933a01675f8275bdc5821e09e52bbe425944855789

import { ConcreteRequest } from 'relay-runtime';
export type deploymentDetailsSchemaPageQuery$variables = {
  id: string;
};
export type deploymentDetailsSchemaPageQuery$data = {
  readonly deployment: {
    readonly apiSchema: string | null | undefined;
  };
};
export type deploymentDetailsSchemaPageQuery = {
  response: deploymentDetailsSchemaPageQuery$data;
  variables: deploymentDetailsSchemaPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiSchema",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsSchemaPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Deployment",
          "kind": "LinkedField",
          "name": "deployment",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsSchemaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88c173a39fdcbf6f4e677d63504dedce",
    "id": "7e12191ee8da6812444102933a01675f8275bdc5821e09e52bbe425944855789",
    "metadata": {},
    "name": "deploymentDetailsSchemaPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsSchemaPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    apiSchema\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "28a2b014ebeb33647c5a31efeeb464b6";

export default node;
