/**
 * @generated SignedSource<<c98efc8339052231889b07284d32aaf7>>
 * @relayHash 2983e94ec7316546cdece281fbbf668e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 283182080a90a357f45379fa782465a141b91cb3037c7b5f8804e03de95bedc4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeploymentStatus = "FAILED" | "IN_PROGRESS" | "QUEUED" | "SUCCEEDED";
export type deploymentLayoutQuery$variables = {
  id: string;
};
export type deploymentLayoutQuery$data = {
  readonly deployment: {
    readonly status: DeploymentStatus;
    readonly " $fragmentSpreads": FragmentRefs<"deploymentLayoutDeploymentStatus" | "deploymentLayoutGeneralInfo">;
  };
};
export type deploymentLayoutQuery = {
  response: deploymentLayoutQuery$data;
  variables: deploymentLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Deployment",
          "kind": "LinkedField",
          "name": "deployment",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "deploymentLayoutGeneralInfo"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "deploymentLayoutDeploymentStatus"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeploymentSubgraph",
            "kind": "LinkedField",
            "name": "subgraph",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeploymentChangeCounts",
            "kind": "LinkedField",
            "name": "changeCounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addedTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "removedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "removedTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2983e94ec7316546cdece281fbbf668e",
    "id": "283182080a90a357f45379fa782465a141b91cb3037c7b5f8804e03de95bedc4",
    "metadata": {},
    "name": "deploymentLayoutQuery",
    "operationKind": "query",
    "text": "query deploymentLayoutQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    status\n    ...deploymentLayoutGeneralInfo\n    ...deploymentLayoutDeploymentStatus\n    id\n  }\n}\n\nfragment deploymentLayoutDeploymentStatus on Deployment {\n  status\n  duration\n  id\n}\n\nfragment deploymentLayoutGeneralInfo on Deployment {\n  id\n  createdAt\n  subgraph {\n    name\n  }\n  ...fieldsTypesDeltas\n}\n\nfragment fieldsTypesDeltas on Deployment {\n  changeCounts {\n    addedFields\n    addedTypes\n    changedFields\n    changedTypes\n    removedFields\n    removedTypes\n  }\n}\n"
  }
};
})();

(node as any).hash = "5df054ef985c06fb4bf077f105cf9481";

export default node;
