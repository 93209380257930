import { createFileRoute } from '@tanstack/react-router'
import { formatISO } from 'date-fns'
import { parseTimeRange } from 'grafbase-components/inputs/time/time-range-parse.ts'
import { parse } from 'valibot'
import { AnalyticsPageSearchSchema } from '#app/pages/branch/analytics/analytics-search-params'
import node, {
  type analyticsPageQuery,
} from '#gen/relay/analyticsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

let now: Date

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/analytics',
)({
  staticData: { title: '[graph] - Analytics' },
  validateSearch(search) {
    return parse(AnalyticsPageSearchSchema, search)
  },
  loaderDeps(opts) {
    return {
      search: opts.search,
    }
  },

  async loader({ context, deps, params, cause }) {
    const { from, to } = parseTimeRange(
      deps.search.timeframe?.range ?? '24h',
      deps.search.timeframe?.from,
      deps.search.timeframe?.to,
    )

    const operationNameFilters = deps.search.filters
      ?.filter(filter => filter[0] === 'operation_name')
      ?.map(([, value]) => value)

    const clientNameFilters = deps.search.filters
      ?.filter(filter => filter[0] === 'client_name')
      ?.map(([, value]) => value)

    const clientVersionFilters = deps.search.filters
      ?.filter(filter => filter[0] === 'client_version')
      ?.map(([, value]) => value)

    // Only update now-timestamp when we enter the route. Not when navigating
    // within it. This avoids triggering a bunch of loading states in the
    // paginated operations list.
    now = cause === 'stay' ? now : to

    const filters = {
      operationName: operationNameFilters?.length
        ? operationNameFilters
        : undefined,
      clientName: clientNameFilters?.length ? clientNameFilters : undefined,
      clientVersion: clientVersionFilters?.length
        ? clientVersionFilters
        : undefined,
    }

    return {
      filters,
      query: await context.preload<analyticsPageQuery>(node, {
        ...params,
        from: formatISO(from),
        to: formatISO(to),
        ...filters,

        // API errors with less than 3 characters, so we just strip the param if we don't have 3 char
        // clientsSearchQuery:
        //   deps.clientsSearchQuery && deps.clientsSearchQuery?.length > 2
        //     ? deps.clientsSearchQuery
        //     : undefined,
        // operationsSearchQuery:
        //   deps.operationsSearchQuery && deps.operationsSearchQuery?.length > 2
        //     ? deps.operationsSearchQuery
        //     : undefined,
      }),
    }
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/analytics/analytics-page'),
    'AnalyticsPage',
  ),
})
