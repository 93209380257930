/**
 * @generated SignedSource<<07193d1ca59f2dcb9f5718c137260ecd>>
 * @relayHash c4e81d4bae9fe3749081074318774414
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1f8eb74020ccb4e9e2b18d436627ece93c0a46357b7d315c0ef98f47a1f53545

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalDiffPageQuery$variables = {
  proposalId: string;
};
export type proposalDiffPageQuery$data = {
  readonly schemaProposal: {
    readonly id: string;
    readonly revision: number;
    readonly subgraphs: ReadonlyArray<{
      readonly diffSnippets: ReadonlyArray<{
        readonly addedLines: ReadonlyArray<number>;
        readonly originalSchemaSnippet: string;
        readonly originalSchemaSnippetFirstLine: number;
        readonly proposedSchemaSnippet: string;
        readonly proposedSchemaSnippetFirstLine: number;
        readonly removedLines: ReadonlyArray<number>;
      }>;
      readonly name: string;
    }>;
    readonly viewerIsReviewer: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"eventsTimelineContextFragment" | "useGetCommentsOnLinesProposalFragment">;
  };
  readonly viewer: {
    readonly avatarUrl: string | null | undefined;
    readonly id: string;
    readonly name: string;
  };
};
export type proposalDiffPageQuery = {
  response: proposalDiffPageQuery$data;
  variables: proposalDiffPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "avatarUrl",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsReviewer",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revision",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SchemaProposalSubgraph",
  "kind": "LinkedField",
  "name": "subgraphs",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DiffSnippet",
      "kind": "LinkedField",
      "name": "diffSnippets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalSchemaSnippet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalSchemaSnippetFirstLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proposedSchemaSnippet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proposedSchemaSnippetFirstLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addedLines",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "removedLines",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v13 = [
  (v2/*: any*/),
  (v1/*: any*/)
],
v14 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalDiffPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v4/*: any*/),
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useGetCommentsOnLinesProposalFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "eventsTimelineContextFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalDiffPageQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activityFeed",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subgraphName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "diffSide",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "contextLines",
                        "value": 5
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "context",
                    "storageKey": "context(contextLines:5)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resolved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "resolvedBy",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaProposalReply",
                    "kind": "LinkedField",
                    "name": "replies",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SchemaProposalCommentOnLine",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v14/*: any*/),
                "type": "SchemaProposalApproval",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v14/*: any*/),
                "type": "SchemaProposalCommentOnProposal",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v14/*: any*/),
                "type": "SchemaProposalCommentOnSdl",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v14/*: any*/),
                "type": "SchemaProposalRejection",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v14/*: any*/),
                "type": "SchemaProposalReviewRequest",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "reviewers",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "onBehalfOf",
                    "plural": true,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "SchemaProposalReviewerUser",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4e81d4bae9fe3749081074318774414",
    "id": "1f8eb74020ccb4e9e2b18d436627ece93c0a46357b7d315c0ef98f47a1f53545",
    "metadata": {},
    "name": "proposalDiffPageQuery",
    "operationKind": "query",
    "text": "query proposalDiffPageQuery(\n  $proposalId: ID!\n) {\n  viewer {\n    id\n    name\n    avatarUrl\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    viewerIsReviewer\n    revision\n    subgraphs {\n      name\n      diffSnippets {\n        originalSchemaSnippet\n        originalSchemaSnippetFirstLine\n        proposedSchemaSnippet\n        proposedSchemaSnippetFirstLine\n        addedLines\n        removedLines\n      }\n    }\n    ...useGetCommentsOnLinesProposalFragment\n    ...eventsTimelineContextFragment\n  }\n}\n\nfragment eventsTimelineContextFragment on SchemaProposal {\n  id\n  reviewers {\n    __typename\n    ... on SchemaProposalReviewerUser {\n      __typename\n      user {\n        id\n      }\n      onBehalfOf {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment eventsTimelineProposalCommentOnLineFragment on SchemaProposalCommentOnLine {\n  id\n  text\n  createdAt\n  author {\n    id\n    name\n    avatarUrl\n  }\n  __typename\n  line\n  subgraphName\n  diffSide\n  context(contextLines: 5)\n  resolved\n  resolvedBy {\n    name\n    id\n  }\n  replies {\n    id\n    text\n    createdAt\n    author {\n      id\n      name\n      avatarUrl\n    }\n  }\n}\n\nfragment useGetCommentsOnLinesProposalFragment on SchemaProposal {\n  activityFeed {\n    __typename\n    ... on SchemaProposalCommentOnLine {\n      __typename\n      id\n      line\n      subgraphName\n      diffSide\n      ...eventsTimelineProposalCommentOnLineFragment\n    }\n    ... on SchemaProposalApproval {\n      id\n    }\n    ... on SchemaProposalCommentOnProposal {\n      id\n    }\n    ... on SchemaProposalCommentOnSdl {\n      id\n    }\n    ... on SchemaProposalRejection {\n      id\n    }\n    ... on SchemaProposalReviewRequest {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "27193d3ff142f406c700b1e51b179775";

export default node;
