/**
 * @generated SignedSource<<11af0219d5622e7ed277a0ad3c517742>>
 * @relayHash c59c085a61328da4776caa4f0ea869e1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0e5a670c2c1f1f88d697c2865c51a1b40bbb2b80474838072e4e149342b15b1e

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type branchLayoutQuery$variables = {
  account: string;
};
export type branchLayoutQuery$data = {
  readonly accountBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"selectsDataWrapperGraphsBranchesFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"selectsDataWrapperAccountsFragment">;
  };
};
export type branchLayoutQuery = {
  response: branchLayoutQuery$data;
  variables: branchLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "branchLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "selectsDataWrapperAccountsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "selectsDataWrapperGraphsBranchesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "branchLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isAccount"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphConnection",
            "kind": "LinkedField",
            "name": "graphs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Graph",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BranchConnection",
                    "kind": "LinkedField",
                    "name": "branches",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BranchEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Branch",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c59c085a61328da4776caa4f0ea869e1",
    "id": "0e5a670c2c1f1f88d697c2865c51a1b40bbb2b80474838072e4e149342b15b1e",
    "metadata": {},
    "name": "branchLayoutQuery",
    "operationKind": "query",
    "text": "query branchLayoutQuery(\n  $account: String!\n) {\n  viewer {\n    ...selectsDataWrapperAccountsFragment\n    id\n  }\n  accountBySlug(slug: $account) {\n    __typename\n    ...selectsDataWrapperGraphsBranchesFragment\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment graphSelectBranchesOfGraph on Graph {\n  branches {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment graphSelectGraphSelectorFragment on Account {\n  __isAccount: __typename\n  graphs {\n    nodes {\n      slug\n      branches {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n  ...graphSelectGraphsListFragment\n}\n\nfragment graphSelectGraphsListFragment on Account {\n  __isAccount: __typename\n  graphs {\n    nodes {\n      slug\n      ...graphSelectItemFragment\n      id\n    }\n  }\n}\n\nfragment graphSelectItemFragment on Graph {\n  slug\n  account {\n    __typename\n    slug\n    id\n  }\n  ...graphSelectBranchesOfGraph\n}\n\nfragment selectsDataWrapperAccountsFragment on User {\n  ...accountSelectFragment\n}\n\nfragment selectsDataWrapperGraphsBranchesFragment on Account {\n  __isAccount: __typename\n  ...graphSelectGraphSelectorFragment\n}\n"
  }
};
})();

(node as any).hash = "950993798aaee3e2bd60cee37087a39e";

export default node;
