// Source: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library#answer-38552302
export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  if (!base64Url) throw new Error('Invalid token')

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export function isJwtValid(jwt: { exp?: number } | null | undefined) {
  if (!jwt) return false
  if (!jwt.exp) return true
  return jwt.exp * 1000 > Date.now()
}
